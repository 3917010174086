import { Col, Row } from 'react-bootstrap';
import React, { Component } from 'react';

class LoadingContent extends Component {
    render () {
        return (
            <section className="container-fluid loading-container h-100 mb-5 mw-100">
                <Row className="h-100 mb-5">
                    <Col className="text-center h-100 mt-5 mb-5">
                        <div className="cp-brandmark anim hovering m-auto d-block mt-5" style={{width: '120px', height: '120px' }}>
                            &nbsp;
                        </div>
                        <span className="ellipses p-5 ms-1 m-auto m-5 cp-loading">
                            LOADING
                        </span>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default LoadingContent;