import React, { Component } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';

class ScrollDown extends Component {
    constructor (props) {
        super(props);
        this.state = {
            hide: false,
            divId: this.props.divId
        };
        this._isMounted = false;
    }

    componentDidMount () {
        this._isMounted = true;
        window.scrollDownInterval = setInterval(() => {
            if (this._isMounted) {
                const elm = document.getElementById(this.state.divId);
                let rect = elm.getBoundingClientRect();
                let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                const hide = rect.bottom > 100 && rect.top - viewHeight < 100;
                if (hide !== this.state.hide) {
                    this.setState({hide});
                }
            }
        }, 100);
    }

    componentWillUnmount () {
        clearInterval(window.scrollDownInterval);
    }

    render () {
        const {hide} = this.state;
        let classes = 'cp-scroll-down text-center anim hovering';
        if (hide) {
            classes += ' hidden';
        }
        return (
            <div className={classes}>
                <div>
                    <span>
                        SCROLL DOWN
                    </span>
                    <ChevronDown/>
                </div>
            </div>
        );
    }
}

export default ScrollDown;
