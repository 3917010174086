import React, { Component } from 'react';
import getWalletConnect from '../getWalletConnect';
import CrocPot from '../contracts/CrocPot.json';
import {
    Col,
    Container,
    Dropdown,
    Row
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import { DemoTokenSvg, TOKEN_NAMES, UrisToTokens } from '../Tokens';
import Message from './Message';

class Wallet extends Component {
    constructor (props) {
        super(props);
        const web3 = window.w3 || this.props.web3 || null;
        const accounts = this.storageGet('w3accounts', []);
        let stage = 'ready';
        if (accounts.length) {
            stage = 'connecting';
        }
        this.state = {
            web3: web3,
            networkId: this.storageGet('w3chainid', null),
            nfts: [],
            tokens: this.storageGet('tokens', []),
            passTypes: this.storageGet('passTypes', {}),
            accounts: accounts,
            stage,
            error: null,
            contract: null,
            requests: []
        };
        this.connect = this.connect.bind(this);
        this.accountsChanged = this.accountsChanged.bind(this);
        this.chainChanged = this.chainChanged.bind(this);
        // this.blockie = this.blockie.bind(this);
        this.loadContract = this.loadContract.bind(this);
        this.offlineMode = this.offlineMode.bind(this);
        this.disconnect = this.disconnect.bind(this);
    }

    accountsChanged (accounts) {
        console.log('Accounts changed ' + (accounts[0] || ''));
        this.storageSet('w3accounts', accounts);
        if (accounts.length) {
            return this.setState({
                accounts,
                stage: 'connected'
            }, this.loadContract);
        }
        else {
            return this.setState({
                accounts,
                stage: 'ready'
            });
        }
    }

    // blockie (account) {
    //     // Ported Blockies implementation to match Etherscan.
    //     let randSeed = new Array(4);
    //
    //     function seedRand (seed) {
    //         for (let i = 0; i < randSeed.length; i++) {
    //             randSeed[i] = 0;
    //         }
    //         for (let i = 0; i < seed.length; i++) {
    //             randSeed[i % 4] = ((randSeed[i % 4] << 5) - randSeed[i % 4]) + seed.charCodeAt(i);
    //         }
    //     }
    //
    //     function rand () {
    //         let t = randSeed[0] ^ (randSeed[0] << 11);
    //         randSeed[0] = randSeed[1];
    //         randSeed[1] = randSeed[2];
    //         randSeed[2] = randSeed[3];
    //         randSeed[3] = (randSeed[3] ^ (randSeed[3] >> 19) ^ t ^ (t >> 8));
    //         return (randSeed[3] >>> 0) / ((1 << 31) >>> 0);
    //     }
    //
    //     function createColor () {
    //         let h = Math.floor(rand() * 360);
    //         let s = ((rand() * 60) + 40) + '%';
    //         let l = ((rand() + rand() + rand() + rand()) * 25) + '%';
    //         return 'hsl(' + h + ',' + s + ',' + l + ')';
    //     }
    //
    //     function createImageData (size) {
    //         let width = size;
    //         let height = size;
    //         let dataWidth = Math.ceil(width / 2);
    //         let mirrorWidth = width - dataWidth;
    //         let data = [];
    //         for (let y = 0; y < height; y++) {
    //             let row = [];
    //             for (let x = 0; x < dataWidth; x++) {
    //                 row[x] = Math.floor(rand() * 2.3);
    //             }
    //             let r = row.slice(0, mirrorWidth);
    //             r.reverse();
    //             row = row.concat(r);
    //             for (let i = 0; i < row.length; i++) {data.push(row[i]);}
    //         }
    //         return data;
    //     }
    //
    //     function createCanvas (imageData, color, scale, bgColor, spotColor) {
    //         let c = document.createElement('canvas');
    //         let width = Math.sqrt(imageData.length);
    //         c.width = c.height = width * scale;
    //         let cc = c.getContext('2d');
    //         cc.fillStyle = bgColor;
    //         cc.fillRect(0, 0, c.width, c.height);
    //         cc.fillStyle = color;
    //         for (let i = 0; i < imageData.length; i++) {
    //             let row = Math.floor(i / width);
    //             let col = i % width;
    //             cc.fillStyle = (imageData[i] === 1) ? color : spotColor;
    //             if (imageData[i]) {cc.fillRect(col * scale, row * scale, scale, scale);}
    //         }
    //         return c;
    //     }
    //
    //     function createIcon (opts) {
    //         opts = opts || {};
    //         let size = opts.size || 8;
    //         let scale = opts.scale || 4;
    //         let seed = opts.seed || Math.floor((Math.random() * Math.pow(10, 16))).toString(16);
    //         seedRand(seed);
    //         let color = opts.color || createColor();
    //         let bgColor = opts.bgColor || createColor();
    //         let spotColor = opts.spotColor || createColor();
    //         let imageData = createImageData(size);
    //         return createCanvas(imageData, color, scale, bgColor, spotColor);
    //     }
    //
    //     return createIcon({
    //         seed: account.toLowerCase(),
    //         scale: 5
    //     }).toDataURL();
    // }

    chainChanged (input) {
        const {web3} = this.state;
        const networkId = typeof input == 'string' ? web3.utils.hexToNumber(input) : input;
        console.log('Chain changed', networkId);
        if (!this.getNetwork(networkId)) {
            return this.setState({
                networkId,
                stage: 'error',
                error: 'Please connect your wallet to the Ethereum Mainnet'
            });
        }
        else {
            return this.setState({
                networkId
            }, this.loadContract);
        }
    }

    async componentDidMount () {
        const {stage} = this.state;
        if (stage === 'connecting') {
            await this.connect();
        }
        else {
            await this.offlineMode();
        }
    }

    async connect () {
        this.setState({
            stage: 'connecting'
        }, async () => {
            try {
                // Get network provider and web3 instance.
                const web3 = window.w3 || await getWalletConnect(this.accountsChanged, this.chainChanged, this.disconnect);
                const networkId = await web3.eth.net.getId();
                window.w3 = web3;
                this.setState({
                    web3,
                    networkId
                }, async () => {
                    if (!this.state.networkId.length) {
                        // Handle situations where the initial hit is on the
                        // wrong network.
                        this.chainChanged(networkId);
                    }
                    if (!this.state.accounts || !this.state.accounts.length) {
                        // Do not attempt eth_requestAccounts with WalletConnect
                        if (typeof window.w3.currentProvider.infuraId === 'undefined') {
                            web3.currentProvider.request({
                                method: 'eth_requestAccounts',
                            }).then(async () => {
                                this.accountsChanged(await web3.eth.getAccounts());
                            }).catch((err) => {
                                if (err.code === 4001) {
                                    // EIP-1193 userRejectedRequest error
                                    // If this happens, the user rejected the
                                    // connection request.
                                    this.disconnect();
                                }
                                else {
                                    console.error(err);
                                }
                            });
                        } else {
                            this.accountsChanged(await web3.eth.getAccounts());
                        }
                    }
                    else {
                        this.accountsChanged(await web3.eth.getAccounts());
                    }
                });
            }
            catch (error) {
                console.warn('disconnecting', error);
                this.setState({
                    stage: 'error',
                    error: 'Please connect your MetaMask (or similar) wallet'
                });
            }
        });
    }

    async disconnect (code, reason) {
        console.log('disconnecting', code, reason);
        window.tokens = null;
        window.w3accounts = [];
        window.sessionStorage.clear();
        this.setState({
            nfts: null,
            tokens: [],
            passTypes: {},
            accounts: [],
            stage: 'ready',
        }, this.offlineMode);
    }

    async getNFTs (callback) {
        const {accounts, contract} = this.state;
        let nfts = [];
        if (contract && accounts.length > 0) {
            let uris = await contract.methods.tokenURIsByOwner(accounts[0]).call();

            let tokens = UrisToTokens(uris);
            this.storageSet('tokens', tokens);

            let d = 0;
            for (let t = 0; t < tokens.length; t++) {
                try {
                    // tokenId, passTypeId, cost, maxTotal, count, status,
                    // token, uri
                    let src = tokens[t].uri.replace('/token/', '/token/svg/');
                    console.log(src);
                    let style = {'animationDelay': (d * 250) + 'ms'};
                    let actions = '';
                    actions = (
                        <div className="nft-actions flex-shrink-1">
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary">
                                    &bull;&bull;&bull;
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        href={'https://opensea.io/' + contract.options.address + '/' + tokens[t].tokenId}
                                        target="_blank">View on OpenSea</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    );
                    let label = '';
                    if (typeof TOKEN_NAMES[tokens[t].passTypeId] !== 'undefined') {
                        label = TOKEN_NAMES[tokens[t].passTypeId];
                    }
                    nfts.push(
                        <div key={t}
                             className="nft">
                            <div className="anim"
                                 style={style}>
                                <div className="flip-in-hor-bottom-2"
                                     style={style}>
                                    <div
                                        className="tilt-in-top-2"
                                        style={style}>
                                        <Tilt glareEnable={true}
                                              glareMaxOpacity={0.8}
                                              glareColor="#ffffff"
                                              glarePosition="bottom"
                                              glareBorderRadius="0px">
                                            <img src={src} alt={label}/>
                                        </Tilt>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex pt-4">
                                <div className="w-100">
                                    <span className="nft-under">{label}</span><br/>
                                    <span className="nft-under">Minted Token #{tokens[t].tokenId}</span>
                                </div>
                                {actions}
                            </div>
                        </div>
                    );
                    d += .8;
                }
                catch (e) {
                    console.error(e);
                }
            }
            if (nfts) {
                this.setState({
                    nfts,
                    tokens
                }, this.getPassTypes);
            }
            else {
                await this.getPassTypes();
            }
        }
        return nfts;
    }

    getNetwork (networkId) {
        return CrocPot.networks[networkId] || false;
    }

    async getPassTypes () {
        const {contract, passTypes, stage} = this.state;
        // Counts may have changed, keep refreshing?
        // if (Object.keys(passTypes).length) {
        //     return passTypes;
        // }
        let newPassTypes = {};
        if (stage === 'connected') {
            for (let i = 0; i <= 255; i++) {
                let type = await contract.methods.passTypes(i).call();
                if (type.destinationA === '0x0000000000000000000000000000000000000000') {
                    // Stop searching for types when we hit an empty one
                    break;
                }
                newPassTypes[i] = {
                    cost: type.cost,
                    count: type.count,
                    maxTotal: type.maxTotal,
                    destinationA: type.destinationA,
                    destinationB: type.destinationB,
                };
            }
        }
        if (newPassTypes !== passTypes) {
            this.storageSet('passTypes', newPassTypes);
            await this.setState({passTypes: newPassTypes});
        }
    }

    loadContract () {
        const {
            networkId,
            accounts,
            web3,
            contract
        } = this.state;
        const network = this.getNetwork(networkId);
        if (
            web3
            && network
            && (typeof accounts !== 'undefined' && accounts && accounts.length > 0)
            && !contract
        ) {
            let contract = new web3.eth.Contract(
                CrocPot.abi,
                network.address,
            );
            this.setState({
                contract
            }, this.getNFTs);
            window.contract = contract;
        }
    }

    async offlineMode () {
        console.log('offline mode');
    }

    redirectTo = (redirect) => {
        if (window.location.pathname !== redirect) {
            this.setState(
                {redirect: redirect},
                () => this.setState({redirect: false})
            );
        }
    };

    render () {
        const {redirect} = this.state;
        if (redirect && window.location.pathname !== redirect) {
            return <Redirect to={redirect}/>;
        }

        if (this.props.nfts) {
            return this.renderNFTs(this.props.lastNFT || false);
        }
        else {
            return this.renderLogin();
        }
    }

    renderLogin () {
        const {stage, error, nfts} = this.state;

        switch (stage) {
            case 'ready':
                return (
                    <>
                        <h1 className="welcome">WELCOME TO</h1>
                        <div className="cp-logo ms-auto me-auto mt-3 mb-3" role="img" alt="CROC-POT"/>
                        <h2 className="mb-5">
                            Revolutionary on-chain project, community members<br/>
                            participate in a combined "pot" of assets
                        </h2>
                        <div id="wallet" className={stage}
                             onClick={this.connect}>
                            <span>CONNECT YOUR WALLET</span>
                        </div>
                        <div className="mt-5 cp-pot m-auto anim slide-in-blurred-top" role="img" />
                    </>
                );
            case 'connecting':
                return (
                    <>
                        <h1 className="welcome">WELCOME TO</h1>
                        <div className="cp-logo ms-auto me-auto mt-3 mb-3" role="img" alt="CROC-POT"/>
                        <h2 className="mb-5">
                            Revolutionary on-chain project, community members<br/>
                            participate in a combined "pot" of assets
                        </h2>
                        <div id="wallet" className="connecting"
                             onClick={this.disconnect}>
                            <span className="ellipses">CONNECTING</span>
                        </div>
                        <div className="mt-5 cp-pot m-auto anim slide-in-blurred-top" role="img" />
                    </>
                );
            case 'connected':
                if (nfts && Object.keys(nfts).length > 0) {
                    // let part2 = accounts[0].substring(accounts[0].length - 9);
                    // let part1 = accounts[0].substring(0, accounts[0].length - part2.length);
                    return (
                        <>
                            <h1 className="welcome">WELCOME BACK TO</h1>
                            <div className="cp-logo ms-auto me-auto mt-3 mb-3" role="img" alt="CROC-POT"/>
                            <h2 className="mb-5">
                                Revolutionary on-chain project, community members<br/>
                                participate in a combined "pot" of assets
                            </h2>
                            {/*<div id="wallet" className="connected"*/}
                            {/*     onClick={() => {*/}
                            {/*         this.redirectTo('/portfolio');*/}
                            {/*     }}>*/}
                            {/*    <div className="portfolio-and-account">*/}
                            {/*        <div className="your-portfolio">*/}
                            {/*            VIEW POT*/}
                            {/*        </div>*/}
                            {/*        <div className="account">*/}
                            {/*            <span className="part1">{part1}</span>*/}
                            {/*            <span className="part2">{part2}</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <img src={this.blockie(accounts[0])}*/}
                            {/*         alt={accounts[0]}/>*/}
                            {/*</div>*/}
                            <div id="wallet" className="ready"
                                 onClick={() => {
                                     this.redirectTo('/portfolio');
                                 }}>
                                <span>VIEW POT</span>
                            </div>
                            <div className="mt-3 cp-pot m-auto anim slide-in-blurred-top" role="img" />
                        </>
                    );
                } else {
                    return (
                        <>
                            <h1 className="welcome">THIS IS YOUR</h1>
                            <div className="cp-logo ms-auto me-auto mt-3 mb-3" role="img" alt="CROC-POT"/>
                            <h1 className="welcome mb-5">
                                EXCLUSIVE PASS
                            </h1>
                            <div id="wallet" className="ready"
                                 onClick={() => {window.mint = true;}}>
                                <span>MINT YOUR PASS</span>
                            </div>
                            <div className="mt-5 pt-5 nft-perspective anim slide-in-blurred-top">
                                <img className="nft anim rotate-vert-center glow" src={DemoTokenSvg(0)} alt="Example NFT"/>
                            </div>
                        </>
                    );
                }
            case 'error':
            default:
                return (
                    <>
                        <h1 className="welcome">WELCOME TO</h1>
                        <div className="cp-logo ms-auto me-auto mt-3 mb-3" role="img" alt="CROC-POT"/>
                        <h2 className="mb-5">
                            Revolutionary on-chain project, community members<br/>
                            participate in a combined "pot" of assets
                        </h2>
                        <div id="wallet" className={stage}>
                            <span className="text-warning">
                                {error}
                            </span>
                        </div>
                        <div className="mt-5 cp-pot m-auto anim slide-in-blurred-top" role="img" />
                    </>
                );
        }
    }

    renderNFTs (lastNFT) {
        const {nfts} = this.state;
        if (!window.w3accounts) {
            return (
                <Message text="Please return to the home page and connect your wallet to see your NFTs"/>
            );
        }
        if (lastNFT) {
            return (
                <>
                    {nfts.slice(-1)}
                </>
            );
        }
        return (
            <div className="container-fluid bg-dark text-light">
                <Container>
                    <Col className="col-12">
                        <Row className="pt-5 bg-dark nft-container m-auto m-lg-0 text-center text-lg-start">
                            <h2 className="mt-4">YOUR CROCPOT PASS{nfts.length > 1 ? 'ES' : ''}</h2>
                        </Row>
                        <Row className="pt-3 bg-dark nft-container pb-5 m-auto m-lg-0">
                            {nfts}
                            <span
                                className="nft d-flex align-items-center float-end clickable"
                                onClick={() => {window.mint = true;}}>
                                <div
                                    className="nft-add-position-plus align-middle m-auto">
                                    <div
                                        className="text-nowrap nft-add-position m-auto text-center mint-another-pass">
                                        Mint {nfts.length > 0 ? 'Another' : 'a'} Pass
                                    </div>
                                </div>
                            </span>
                        </Row>
                    </Col>
                </Container>
            </div>
        );
    }

    storageGet = (key, def) => {
        if (typeof window[key] !== 'undefined') {
            return window[key];
        }
        let str = window.sessionStorage.getItem(key) || '';
        if (str.length) {
            try {
                return JSON.parse(str);
            }
            catch (e) {
                def = str;
                // console.error(e);
            }
        }
        return def;
    };

    storageSet = (key, value) => {
        window[key] = value;
        window.sessionStorage.setItem(key, JSON.stringify(value));
    };
}

export default Wallet;
