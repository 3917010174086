import React, { Component } from 'react';
import PortfolioInvestmentExpanded from './PortfolioInvestmentExpanded';
// import LoadingContent from './LoadingContent';
// import { DemoToken } from '../Tokens';

class PortfolioInvestment extends Component {

    constructor (props) {
        super(props);
        this._isMounted = false;

        // if (!window.tokens) {
        //     window.tokens = [
        //         [DemoToken(0, '0')]
        //     ];
        // }
        this.state = {
            tokens: window.tokens || [],
        };
    }

    componentDidMount () {
        this._isMounted = true;
        window.pireloader = setInterval(() => {
            if (this._isMounted) {
                if (window.tokens && window.tokens !== this.state.tokens) {
                    // clearInterval(window.pireloader);
                    this.setState({
                        tokens: window.tokens
                    });
                }
            } else {
                clearInterval(window.pireloader);
            }
        }, 500);
    }

    componentWillUnmount () {
        this._isMounted = false;
    }

    render () {
        const {tokens} = this.state;
        // if (!tokens) {
        //     return (
        //         <LoadingContent/>
        //     );
        // }
        return (
            <PortfolioInvestmentExpanded tokens={tokens}
                                         demo={false}/>
        );
    }
}

export default PortfolioInvestment;
