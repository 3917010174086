import React, { Component } from 'react';

class Mirror extends Component {
    constructor (props) {
        super(props);
        this.state = {
            divId: this.props.divId,
            html: '',
            classList: ''
        };
        this._isMounted = false;
        this.forwardClick = this.forwardClick.bind(this);
    }

    componentDidMount () {
        this.mirrorInterval = setInterval(() => {
            if (this._isMounted) {
                const elm = document.getElementById(this.state.divId);
                const html = elm ? elm.innerHTML : '';
                if (html !== this.state.html) {
                    this.setState({
                        html,
                        classList: elm.classList
                    });
                }
            }
        }, 500);
        this._isMounted = true;
    }

    componentWillUnmount () {
        clearInterval(this.mirrorInterval);
    }

    forwardClick() {
        const elm = document.getElementById(this.state.divId);
        elm.click();
    }

    render () {
        const {divId, html, classList} = this.state;
        return (
            <div id={divId}
                 className={classList + ' mirrored-element'}
                 dangerouslySetInnerHTML={{__html: html}}
                 onClick={this.forwardClick}
            />
        );
    }
}

export default Mirror;
