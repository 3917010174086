import React, { Component } from 'react';
import {
    CloseButton,
    Modal
} from 'react-bootstrap';
import Subscribe from './Subscribe';

class SubScribeModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            show: window.subscribe || false,
        };
        this._isMounted = false;
        clearInterval(window.subscribeReload);
    }

    componentDidMount () {
        this._isMounted = true;
        window.subscribeReload = setInterval(() => {
            if (
                window.subscribe !== this.state.show
                && this._isMounted
            ) {
                this.setState({
                    show: window.subscribe
                });
            }
        }, 250);
    }

    componentWillUnmount () {
        this._isMounted = false;
    }

    render () {
        const {show} = this.state;

        return (
            <Modal
                size="md"
                animation={false}
                show={show}
                aria-labelledby="contained-modal-title-login"
                centered
                onHide={() => {}}
            >
                <CloseButton aria-label="Hide"
                             onClick={() => {
                                 window.subscribe = false;
                                 this.setState({
                                     'show': false,
                                 });
                             }}>
                    <div className="cp-cancel">&nbsp;</div>
                </CloseButton>
                <Modal.Header>
                    <h2 className="m-auto">Stay in the Loop</h2>
                </Modal.Header>
                <Subscribe/>
            </Modal>
        );
    }

}

export default SubScribeModal;