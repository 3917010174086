import Wallet from '../components/Wallet';
import Footer from '../components/Footer';
import React from 'react';
import PortfolioInvestment from '../components/PortfolioInvestment';
import Link from '../components/Link';

const Portfolio = () => {
    document.body.classList.add('portfolio');
    document.body.classList.remove('home');
    return (
        <>
            <div className="nav navbar navbar-dark mt-0 pt-0">
                <Link to="/" className="no-underline cp-brandmark">&nbsp;
                </Link>
                <Link to="/" className="no-underline wordmark">
                    CROCPOT
                </Link>
            </div>
            <div className="cf-portfolio-body container-fluid p-0">
                <PortfolioInvestment/>
            </div>
            <Wallet nfts={true} modal={true}/>
            <Footer/>
        </>
    );
};

export default Portfolio;