import React, { Component } from 'react';
import MintModal from './MintModal';
import { Container, Row } from 'react-bootstrap';
import { Mailbox, Telegram } from 'react-bootstrap-icons';
import Link from './Link';
import SubScribeModal from './SubScribeModal';

class Footer extends Component {
    stayConnected() {
        window.subscribe = true;
    }

    render () {
        return (
            <footer className="container-fluid cp-footer">
                <Container>
                    <Row>
                        <div className="mt-5 mb-5">
                            <span className="signature-sm me-lg-4 d-block d-lg-inline-block mb-4 mb-lg-0" role="img"/>
                            <span className="copyright d-block d-lg-inline-block mb-4 mb-lg-0 text-center text-lg-start" key="copyright">
                                &copy; CrocPot {new Date().getFullYear()}. All Rights Reserved.
                            </span>
                            <span className="cp-telegram float-lg-end d-block d-lg-inline-block text-center text-lg-start">
                                <Link to="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxNTk1NDk0Mjg0">
                                    <Telegram/> &nbsp; JOIN PRIVATE TELEGRAM WITH YOUR PASS
                                </Link>
                            </span>
                            <span className="cp-telegram float-lg-end d-block d-lg-inline-block text-center text-lg-start me-5">
                                <Link to="https://t.me/stockcroc">
                                    <Telegram/> &nbsp; JOIN FREE TELEGRAM
                                </Link>
                            </span>
                            <span className="cp-telegram float-lg-end d-block d-lg-inline-block text-center text-lg-start me-5">
                                <a onClick={this.stayConnected} href="#connect">
                                    <Mailbox/> &nbsp; STAY CONNECTED
                                </a>
                            </span>
                        </div>
                    </Row>
                </Container>
                <MintModal/>
                <SubScribeModal/>
            </footer>
        );
    }
}

export default Footer;
