import { Col, Container, Row } from 'react-bootstrap';
import { TOKEN_COLORS, TOKEN_NAMES, DemoTokenSvg } from '../Tokens';
import React, { Component } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import Tilt from 'react-parallax-tilt';

class Benefits extends Component {
    constructor (props) {
        super(props);
        this.state = {
            show: window.benefits || false
        };
        this._isMounted = false;
        this.toggleBenefits = this.toggleBenefits.bind(this);
        clearInterval(window.benefitsReload);
    }

    componentDidMount () {
        this._isMounted = true;
        window.benefitsReload = setInterval(() => {
            if (
                window.benefits !== this.state.show
                && this._isMounted
            ) {
                this.setState({
                    show: window.benefits
                });
            }
        }, 350);
    }

    componentWillUnmount () {
        this._isMounted = false;
    }

    render () {
        const {show} = this.state;

        return (
            <div
                className={'container-fluid text-center cp-star-background ' + (show ? '' : 'hidden')}>
                <Container>
                    <Row>
                        <h1 className="mt-5">Mint a CrocPot Pass</h1>
                        <p className="">
                            to receive the exclusive benefits available only by inclusion in the CrocPot community:
                        </p>
                    </Row>
                    <Row id="target-nft-memberships">
                        <Col className="col-12 col-lg-3">
                            <div
                                className="mt-5 pt-5 nft-perspective2 anim-lg slide-in-blurred-top2 hovering">
                                <Tilt glareEnable={true}
                                      glareMaxOpacity={0.2}
                                      glareColor="#ffffff"
                                      glarePosition="bottom"
                                      glareBorderRadius="0px">
                                <img className="nft" src={DemoTokenSvg(0)}
                                     alt="Example NFT"/>
                                </Tilt>
                            </div>
                            <div
                                className={'cp-benefits mt-lg-5 pt-lg-5 ' + (show ? '' : 'hidden')}>
                                <div
                                    className="cp-token-description mb-5 mt-lg-5 pt-lg-5"
                                    style={{color: TOKEN_COLORS[0]}}>
                                    {TOKEN_NAMES[0]}
                                </div>
                                <span className="cp-benefit">
                                     Access exclusive Telegram channels
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Participate in curating NFT and asset portfolios.
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Fractional ownership on Premium NFTs
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Contribute to the CrocPot DAO
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Retain pass for <strong>lifetime membership</strong> or resell without fees
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Receive <strong>airdrops</strong> of NFTs and assets directly
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Receive <strong>early access</strong> invitations to other NFTs
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Early access to the StockCroc app (coming soon)
                                </span>
                            </div>
                        </Col>
                        <Col className="col-12 col-lg-3 mt-lg-5 pt-lg-5">
                            <div
                                className="mt-5 pt-5 nft-perspective2 anim-lg slide-in-blurred-bottom2 hovering2">
                                <Tilt glareEnable={true}
                                      glareMaxOpacity={0.8}
                                      glareColor="#ffffff"
                                      glarePosition="bottom"
                                      glareBorderRadius="0px">
                                    <img className="nft" src={DemoTokenSvg(1)}
                                         alt="Example NFT"/>
                                </Tilt>

                            </div>
                            <div
                                className={'cp-benefits ' + (show ? '' : 'hidden')}>
                                <div
                                    className="cp-token-description mb-5 mt-lg-5 pt-lg-5"
                                    style={{color: TOKEN_COLORS[1]}}>
                                    {TOKEN_NAMES[1]}
                                </div>
                                <span className="cp-benefit">
                                     Access exclusive Telegram channels
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Participate in curating NFT and asset portfolios.
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Fractional ownership on Premium NFTs
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Contribute to the CrocPot DAO
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Retain pass for <strong>lifetime membership</strong> or resell without fees
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Receive <strong>airdrops</strong> of NFTs and assets directly
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Receive <strong>early access</strong> invitations to other NFTs
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Early access to the StockCroc app (coming soon)
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Enjoy a <strong>10x</strong> DAO governance vote
                                </span>
                                {/* <hr/>
                                <span className="cp-benefit">
                                     Get dividend payments for pot's profit quarterly
                                </span> */}
                            </div>

                        </Col>
                        <Col className="col-12 col-lg-3">
                            <div
                                className="mt-5 pt-5 nft-perspective2 anim-lg slide-in-blurred-top2 hovering3">
                                <Tilt glareEnable={true}
                                      glareMaxOpacity={0.5}
                                      glareColor="#ffffff"
                                      glarePosition="bottom"
                                      glareBorderRadius="0px">
                                    <img className="nft" src={DemoTokenSvg(2)}
                                         alt="Example NFT"/>
                                </Tilt>

                            </div>
                            <div
                                className={'cp-benefits mt-lg-5 pt-lg-5 ' + (show ? '' : 'hidden')}>
                                <div
                                    className="cp-token-description mb-5 mt-lg-5 pt-lg-5"
                                    style={{color: TOKEN_COLORS[2]}}>
                                    {TOKEN_NAMES[2]}
                                </div>
                                <span className="cp-benefit">
                                     Access exclusive Telegram channels
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Participate in curating NFT and asset portfolios.
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Fractional ownership on Premium NFTs
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Contribute to the CrocPot DAO
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Retain pass for <strong>lifetime membership</strong> or resell without fees
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Receive <strong>airdrops</strong> of NFTs and assets directly
                                </span>
                            </div>
                        </Col>
                        <Col className="col-12 col-lg-3 mt-lg-5 pt-lg-5">
                            <div
                                className="mt-5 pt-5 nft-perspective2 anim-lg slide-in-blurred-bottom2 hovering4">
                                <Tilt glareEnable={true}
                                      glareMaxOpacity={0.2}
                                      glareColor="#ffffff"
                                      glarePosition="bottom"
                                      glareBorderRadius="0px">
                                    <img className="nft" src={DemoTokenSvg(3)}
                                         alt="Example NFT"/>
                                </Tilt>

                            </div>
                            <div
                                className={'cp-benefits ' + (show ? '' : 'hidden')}>
                                <div
                                    className="cp-token-description mb-5 mt-lg-5 pt-lg-5"
                                    style={{color: TOKEN_COLORS[3]}}>
                                    {TOKEN_NAMES[3]}
                                </div>
                                <span className="cp-benefit">
                                     Access exclusive Telegram channels
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Participate in curating NFT and asset portfolios.
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                    Fractional ownership on Premium NFTs
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Contribute to the CrocPot DAO
                                </span>
                                <hr/>
                                <span className="cp-benefit">
                                     Retain pass for <strong>lifetime membership</strong> or resell without fees
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-none d-lg-block cp-benefit-toggle">
                        <div onClick={this.toggleBenefits}>
                            <span>
                            {
                                show ? <>Hide membership benefits <ChevronUp/></> : <>Show membership benefits <ChevronDown/></>
                            }
                            </span>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }

    toggleBenefits () {
        const {show} = this.state;
        window.benefits = !show;
        this.setState({'show': window.benefits});
    }
}

export default Benefits;
