import React, { Component } from 'react';
import { TOKEN_LIMIT } from '../Tokens';
import AnimatedNumber from 'animated-number-react';

class Counter extends Component {
    constructor (props) {
        super(props);
        this.state = {
            totalSupply: window.cptotalsupply || TOKEN_LIMIT,
            limit: TOKEN_LIMIT,
            checked: window.cptotalsupply ? true : false,
        };
        this._isMounted = false;
    }

    componentDidMount () {
        this._isMounted = true;
        setTimeout(() => {
            if (!window.cptotalsupply) {
                this.refresh();
            }
            this.updateTotalSupplyInterval = setInterval(() => {
                this.refresh();
            }, 30000);
        }, 1500);
    }

    componentWillUnmount () {
        clearInterval(this.updateTotalSupplyInterval);
    }

    formatValue = (value) => Math.round(value);

    isConnected () {
        return window.w3
            && window.contract
            && window.w3accounts;
    }

    refresh () {
        if (this.isConnected() && this._isMounted) {
            try {
                window.contract.methods.totalSupply()
                    .call()
                    .then((value) => {
                        const totalSupply = parseInt(value);
                        if (totalSupply !== this.state.totalSupply) {
                            window.cptotalsupply = totalSupply;
                            this.setState({
                                totalSupply,
                                checked: true
                            });
                        }
                    });
            } catch (e) {
                console.error(e);
            }
        }
    }

    render () {
        const {totalSupply, limit, checked} = this.state;
        const available = Math.max(0, limit - totalSupply);
        const prefix = checked ? available > 0 ? 'STILL AVAILABLE' : 'UNAVAILABLE' : 'CHECKING STATUS';
        const str = available.toString().padStart(limit.toString().length, '0');
        let numbers = [];
        let duration = 300;
        for (let c = 0; c < str.length; c++) {
            numbers.push(
                <span className="cp-counter-number" key={c}>
                            <AnimatedNumber
                                value={parseInt(str[c])}
                                formatValue={this.formatValue}
                                duration={duration}
                            />
                        </span>
            );
            duration += 550;
        }
        return (
            <div className="cp-counter">
                <span className="cp-counter-prefix">
                    {prefix}
                </span>
                {numbers}
                <span className="cp-counter-suffix">
                    / {limit}
                </span>
            </div>
        );
    }
}

export default Counter;
