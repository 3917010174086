// Pages
import Home from './pages/home';
import Portfolio from './pages/portfolio';
import Count from './pages/count';

const Routes = {
    '/': {
        page: Home,
        name: 'Home',
        title: 'Crock-Pot',
        header: true,
        footer: false,
        portfolio: false,
        app: false,
        exact: true,
        bodyClass: 'home',
    },
    '/portfolio': {
        page: Portfolio,
        name: 'Your Pot',
        title: 'My CrocPot Portfolio',
        header: false,
        footer: true,
        portfolio: true,
        app: true,
        exact: false,
        bodyClass: 'portfolio',
    },
    '/count': {
        page: Count,
        name: 'Count',
        title: 'Passes Remaining',
        header: false,
        footer: true,
        portfolio: true,
        app: true,
        exact: false,
        bodyClass: 'count',
    }
};

export default Routes;

export function IsAbsolute (uri) {
    if (typeof uri === 'undefined') {
        return false;
    }
    return uri.indexOf('http') === 0;
}
