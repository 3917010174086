import { Col, Container, Row, SafeAnchor } from 'react-bootstrap';
import React from 'react';
import Wallet from '../components/Wallet';
import Footer from '../components/Footer';
import ScrollDown from '../components/ScrollDown';
import Benefits from '../components/Benefits';
import { ChevronDown} from 'react-bootstrap-icons';
import Mirror from '../components/Mirror';

const Home = () => {
    document.body.classList.add('home');
    document.body.classList.remove('portfolio');
    return (
        <>
            <section className="container-fluid">
                <Container className="text-center">
                    <Row>
                        <Col className="col-12 mt-5 pt-2">
                            <div className="home-wallet-holder">
                                <Wallet teaser={true}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="cp-grid2 anim"/>
            <ScrollDown divId="main-body"/>
            <div id="main-body">
                <Container className="mb-5">
                    <Row>
                        <Col
                            className="col-12 offset-lg-3 col-lg-6 text-center mb-5">
                            <h1>
                                Join the Pot, <br/>
                                Slow Cooking Crypto Profits
                            </h1>
                        </Col>
                    </Row>
                    <Row className="mt-5 pt-5">
                        <Col className="col-12 col-lg-6">
                            <div className="cp-benefits-hand01" role="img"/>
                        </Col>
                        <Col className="col-12 col-lg-5 offset-lg-1 pe-5">
                            <h3 className="mt-5 pt-lg-5">Pot Mentality</h3>
                            <p>
                                The Crypto Market can shift millions or billions in an hour which leads to difficult trading environments for many individuals.
                                How does the <strong>average person</strong> do exhaustive research, identify <strong>technical trends</strong> and monitor social influence,
                                stay on top of "the next big thing" in blockchain tech? <strong>Through the Crocpot.</strong>
                            </p>
                            <SafeAnchor href="#target-nft-memberships"
                                        onClick={() => {window.benefits = true;}}>
                                COMPARE THE MEMBERSHIPS <ChevronDown/>
                            </SafeAnchor>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="col-12 col-lg-5 offset-lg-1 pe-5">
                            <h3 className="mt-lg-5 pt-lg-5">What's Cookin'</h3>
                            <p>
                                Community members participate in a combined "pot" of assets.
                                Selected by the governance DAO, these range from stable assets, like BTC, ETH and more, to NFT's (Apes, Lions, Cats, Rats oh my...)
                                and many other forms of blockchain-based assets.
                                <strong>These assets are acquired, held, traded and sold by and for the profit of the Pot Holders.</strong>
                                <br/><br/>
                                Each minted pass represents the associated % ownership stake on the assets in the pot. <strong>The pot starts at 550 ETH</strong>
                            </p>
                            {/*<span className="a"><ChevronLeft/> DOCUMENTATION INCOMING <ChevronRight/></span>*/}
                            {/*<Link download*/}
                            {/*      to='/assets/PDFTBD.pdf'*/}
                            {/*      onClick={()=>{window.open('/assets/PDFTBD.pdf');return false;}}>*/}
                            {/*    DOWNLOAD THE DOCUMENTATION <ChevronRight/>*/}
                            {/*</Link>*/}
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <div className="cp-benefits-hand02" role="img"/>
                        </Col>
                    </Row>
                </Container>
                <Benefits/>
                <Container>
                    <Row>
                        <Col
                            className="col-12 offset-lg-3 col-lg-6 text-center mt-5 mb-5">
                            <p className="cp-text-pre-footer mb-5 pt-lg-4">
                                What are you waiting for?<br/>
                                Jump into the CrocPot today!
                            </p>
                            <Mirror divId="wallet"/>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        </>
    );
};

export default Home;