import { Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';

class Subscribe extends Component {
    render () {
        return (
            <div>
                <section className="container-fluid bg-dark pb-5">
                    <div className="bg-pattern text-light text-center">
                        <Container>
                            <Row>
                                <Col className="mt-3">
                                    <div className="cf-footer-subscribe">
                                        <p>
                                            Subscribe to our newsletter and get thew latest product updates,
                                            exclusive facts and news!
                                        </p>
                                        <div id="mc_embed_signup"
                                             className="mt-5">
                                            <form
                                                action="https://fund.us5.list-manage.com/subscribe/post?u=fbd94aca31dcfb0280a15850e&amp;id=b736c68d42"
                                                method="post"
                                                id="mc-embedded-subscribe-form2"
                                                name="mc-embedded-subscribe-form"
                                                className="validate"
                                                target="_blank">
                                                <div
                                                    id="mc_embed_signup_scroll">
                                                    {/*<label*/}
                                                    {/*    htmlFor="mce-EMAIL">Email</label>*/}
                                                    <div
                                                        className="input-group mb-4 pb-2">
                                                        <input type="email"
                                                               name="EMAIL"
                                                               placeholder="email@address.com"
                                                               required={true}
                                                               className="required email form-control"
                                                               id="mce-EMAIL"/>
                                                        <input
                                                            type="submit"
                                                            value="Subscribe"
                                                            name="subscribe"
                                                            id="mc-embedded-subscribe"
                                                            className="form-control btn btn-primary"
                                                            style={{'maxWidth': '130px'}}
                                                        />
                                                    </div>
                                                    <div id="mce-responses"
                                                         className="clear">
                                                        <div
                                                            className="response"
                                                            id="mce-error-response"
                                                            style={{display: 'none'}}>&nbsp;</div>
                                                        <div
                                                            className="response"
                                                            id="mce-success-response"
                                                            style={{display: 'none'}}>&nbsp;</div>
                                                    </div>
                                                    <div
                                                        className="d-none mc-field-group input-group"
                                                        style={{
                                                            position: 'absolute',
                                                            left: '-5000px'
                                                        }}
                                                        aria-hidden="true">
                                                        <strong>Interests</strong>
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type="checkbox"
                                                                    value="1"
                                                                    name="group[32018][1]"
                                                                    id="mce-group[32018]-32018-0">
                                                                    {/*<label*/}
                                                                    {/*    htmlFor="mce-group[32018]-32018-0">Caiman Fund</label>*/}
                                                                </input>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="checkbox"
                                                                    value="2"
                                                                    name="group[32018][2]"
                                                                    id="mce-group[32018]-32018-1">
                                                                    {/*<label*/}
                                                                    {/*    htmlFor="mce-group[32018]-32018-1">CrocPot</label>*/}
                                                                </input>
                                                            </li>
                                                            <li>
                                                                <input
                                                                    type="checkbox"
                                                                    value="4"
                                                                    checked readOnly
                                                                    name="group[32018][4]"
                                                                    id="mce-group[32018]-32018-2">
                                                                    {/*<label*/}
                                                                    {/*    htmlFor="mce-group[32018]-32018-2">StockCroc</label>*/}
                                                                </input>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div
                                                        className="d-none"
                                                        style={{
                                                            position: 'absolute',
                                                            left: '-5000px'
                                                        }}
                                                        aria-hidden="true">
                                                        <input
                                                            type="text"
                                                            name="b_fbd94aca31dcfb0280a15850e_b736c68d42"
                                                            tabIndex="-1"/>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
            </div>
        );
    }
}

export default Subscribe;
