import Counter from '../components/Counter';
import Wallet from '../components/Wallet';
import Footer from '../components/Footer';
import React from 'react';

const Count = () => {
    return (
        <>
            <section className="container-fluid">
                <div
                    className="page-wrap d-flex flex-row align-items-center mt-5">
                    <div className="container mt-5">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <span className="d-block mt-5 mb-5">
                                    <Counter/>
                                </span>
                                <a href="/"
                                   className="btn btn-info">Back Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Wallet teaser={false} nfts={true}/>
            <Footer/>
        </>
    );
};

export default Count;