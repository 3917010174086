import {
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Row
} from 'react-bootstrap';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, Interaction } from 'chart.js';
import 'chartjs-adapter-date-fns';
import LoadingContent from './LoadingContent';
// import Message from './Message';
import { SYMBOL_SVG, tokensToString } from '../Tokens';
import Link from './Link';
// import DataTable, { createTheme } from 'react-data-table-component';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';

const CHART_URL = 'https://crocpot.io/api/chart';

// const ASSETS_URL = 'https://crocpot.io/api/assets';

const CHART_PLUGINS = [{
    id: 'custom_canvas_background_color',
    beforeDraw: (chart) => {
        const ctx = chart.canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = '#45D2B4';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}];

const CHART_OPTIONS = {
    responsive: true,
    scales: {
        y: {
            stacked: true,
            display: false,
            // Keeps us looking fluid
            // beginAtZero: true,
        },
        x: {
            display: false,
            type: 'time',
            ticks: {
                color: '#A6E76D',
                font: {
                    size: 14,
                    weight: 800,
                },
                defaultFontSize: true,
                autoSkip: true,
                maxTicksLimit: 3,
                maxRotation: 0,
                minRotation: 0
            }
        },
    },
    layout: {
        padding: {
            top: 70
        }
    },
    animations: {
        tension: {
            duration: 1500,
            easing: 'easeInOutSine',
            from: .28,
            to: .55,
            loop: true
        },
        // y: {
        //     duration: 1000,
        //     easing: 'easeInOutSine',
        //     // from: '100%',
        //     // to: '90%',
        //     loop: false
        // }
    },
    spanGaps: true,
    animation: {
        duration: 2400,
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            animation: false,
            // Interpolation mode not yet working....
            // mode: 'interpolate',
            intersect: true,
            callbacks: {
                label: function (context) {
                    let label = ' ';
                    if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD'
                        }).format(context.parsed.y);
                    }
                    return label;
                },
                title: function () {
                    return '';
                }
            },
        },
        crosshair: {
            line: {
                color: '#A6E76D',
                width: 2
            },
            sync: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },
        }
    }
};

class PortfolioInvestmentExpanded extends Component {

    constructor (props) {
        super(props);

        this.state = {
            data: {},
            tokens: props.tokens || [],
            types: window.types || [],
            fundData: window.funddata || {},
            zoom: '1w',
            stage: 'loading'
        };

        Chart.register(CrosshairPlugin);
        Interaction.modes.interpolate = Interpolate;

        // createTheme('custom', {
        //     text: {
        //         primary: '#32D2B4',
        //         secondary: 'rgba(255, 255, 255, 0.5)',
        //     },
        //     background: {
        //         default: '#2c2a76',
        //     },
        //     context: {
        //         background: '#cb4b16',
        //         text: '#FFFFFF',
        //     },
        //     divider: {
        //         default: '#073642',
        //     },
        //     action: {
        //         button: 'rgba(0,0,0,.54)',
        //         hover: 'rgba(0,0,0,.08)',
        //         disabled: 'rgba(0,0,0,.12)',
        //     },
        // }, 'dark');

        this.zoomChange.bind(this);
    }

    componentDidMount () {
        // Chart.register({
        //     id: 'hover',
        //     afterDraw: function (chart, easing) {
        //         // window.tmp = chart;
        //         if (
        //             chart &&
        //             typeof chart !== 'undefined' &&
        //             typeof chart.tooltip !== 'undefined' &&
        //             chart.tooltip._active &&
        //             chart.tooltip._active.length &&
        //             chart.config.type === 'line'
        //         ) {
        //             const activePoint = chart.tooltip._active[0];
        //             window.activePoint = activePoint;
        //             const ctx = chart.ctx;
        //             const x = activePoint.element.x;
        //             const topY = chart.scales['y'].top;
        //             const bottomY = chart.scales['y'].bottom;
        //             ctx.save();
        //             ctx.beginPath();
        //             ctx.moveTo(x, topY);
        //             ctx.lineTo(x, bottomY);
        //             ctx.lineWidth = 2;
        //             ctx.strokeStyle = '#A6E76D';
        //             ctx.stroke();
        //             ctx.restore();
        //         }
        //     }
        // });
        this.getData();
    }

    componentDidUpdate () {
        const {tokens} = this.state;
        if (tokens.length && tokens !== (this.props.tokens || [])) {
            console.log('token change', tokens, this.props.tokens);
            this.setState({
                tokens: this.props.tokens,
                stage: 'loading'
            }, this.getData);
        }
    }

    date (str) {
        if (str) {
            let d = new Date(str);
            let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
            let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d);
            let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
            return `${mo} ${da}, ${ye}`;
        }
        return '';
    }

    async getData (z) {
        const {tokens, zoom} = this.state;
        // if (tokens.length === 0) {
        //     return;
        // }

        if (typeof z == 'undefined') {
            z = zoom;
        }
        const url = [CHART_URL, z, tokensToString(tokens)].join('/');
        console.log('FETCH', url);
        let data = await fetch(url)
            .then(response => response.json());

        if (typeof data.datasets !== 'undefined') {
            for (let d = 0; d < data.datasets.length; d++) {
                data.datasets[d].backgroundColor = '#211F6F';
                data.datasets[d].borderColor = '#2a277e';
                data.datasets[d].fill = true;
                data.datasets[d].showLine = true;
                data.datasets[d].borderWidth = 0.00000001;
                data.datasets[d].pointRadius = 0;
                data.datasets[d].pointHitRadius = 50;
                data.datasets[d].lineTension = .3;
                data.datasets[d].interpolate = true;
            }
        }
        this.setState({
            data,
            zoom: z,
            stage: 'ready'
        });
    }

    render () {
        let {data, stage} = this.state;

        if (stage === 'loading') {
            return (
                <LoadingContent/>
            );
        }

        // if (!tokens.length) {
        //     return (
        //         <Message text="Please log in to view your pot"/>
        //     );
        // }

        let values = {};
        if (typeof data.meta !== 'undefined') {
            values = data.meta.values;
        }

        let times = {};
        if (typeof data.meta !== 'undefined') {
            times = data.meta.times;
        }

        let coins = [];
        // let assets = [];
        if (typeof data.meta.assets !== 'undefined') {
            console.log(data.meta.assets);
            for (let a = 0; a < data.meta.assets.length; a++) {
                // Listed on Coingecko = a "COIN" vs a standard asset.
                let symbol = data.meta.assets[a].symbol !== null ? data.meta.assets[a].symbol.toLowerCase() : '';
                let image = data.meta.assets[a].image || null;
                let name = data.meta.assets[a].name || '';
                let website = data.meta.assets[a].website || '#';
                if (SYMBOL_SVG.indexOf(symbol) !== -1) {
                    image = '/img/crypto/yellow/' + symbol + '.svg';
                }
                if (image !== null) {
                    image = (
                        <img
                            src={image}
                            alt={symbol}
                            width={36} height={36}/>
                    );
                }
                if (data.meta.assets[a].percent !== '0%') {
                    coins.push(
                        <Row key={a}>
                            <div className="cp-investment-coin">
                        <span className="float-start crypto-icon">
                            {image}
                        </span>
                                <div className="">
                                <span
                                    className="currency">
                                    {name}
                                </span>
                                    <Link className="currency-name"
                                          target="_blank"
                                          to={website}>
                                        {symbol.toUpperCase() || ''}
                                    </Link>
                                    <div
                                        className="float-end percentage">
                                        {data.meta.assets[a].percent || 0}
                                        <br/>
                                        <span
                                            className="value">
                                            {data.meta.assets[a].value || ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    );
                }
            }
        }

        let chartStyle = {opacity: 1};
        if (stage === 'zooming') {
            chartStyle = {opacity: .85};
        }

        let plCardDaily = '';
        let plCardTotal = '';
        plCardDaily = (
            <Card className="mb-4">
                <div className="card-body">
                    <div
                        className="cp-investment-header">DAILY P/L
                    </div>
                    <p className="card-text cp-investment-stats">
                            <span
                                className="cp-investment-usd">
                                {values.dailyChange || ''}
                            </span>
                        <br/>
                        <span
                            className={values.dailyPLClass || ''}>
                                {values.dailyPL || ''}
                            </span>
                    </p>
                </div>
            </Card>
        );
        plCardTotal = (
            <Card className="mb-4">
                <div className="card-body">
                    <div
                        className="cp-investment-header">TOTAL P/L
                    </div>
                    <p className="card-text cp-investment-stats">
                            <span
                                className="cp-investment-usd">
                                {values.totalChange || ''}
                            </span>
                        <br/>
                        <span
                            className={values.totalPLClass || ''}>
                                {values.totalPL || ''}
                            </span>
                    </p>
                </div>
            </Card>
        );
        return (
            <>
                <div className="cp-portfolio-header">
                    <Container>
                        <Row>
                            <Col className="col-12 col-lg-8 mt-4">
                                <div>
                                    {/*<div*/}
                                    {/*    className={titleClass}>*/}
                                    {/*    {title}*/}
                                    {/*    /!*• {label}*!/*/}
                                    {/*</div>*/}
                                    {this.zoomLinks()}
                                    <div
                                        className="cp-investment-usd-lg">
                                        {values.finalValue || ''} USD
                                    </div>
                                    <div
                                        className="cp-investment-stats">
                                    <span className={values.totalPLClass || ''}>
                                        {values.totalPL || ''}
                                    </span>
                                        <span className="pull-end">
                                        {this.date(times.endTime || null)}
                                    </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Col className="col-12 col-lg-8 cp-investment-chart anim tilt-in-top-2 hovering sliding" style={chartStyle}>
                    <Line id="canvas"
                          data={data}
                          options={CHART_OPTIONS}
                          plugins={CHART_PLUGINS}
                    />
                </Col>
                <Col className="col-12 col-lg-4 cp-fund-sidebar">
                    <h2 className="pt-4 mt-4 mb-5 cp-the-pot">YOUR POT ASSETS</h2>
                    <div className="cp-investment-coins mb-5">
                        {coins}
                    </div>
                </Col>
                <Container>
                    <Row>
                        <h4>
                            Pot overview
                        </h4>
                        <Col className="col-12 col-lg-8 mb-5">
                            <div className="cp-investment-overview">
                                <Row className="row mt-3">
                                    <Col className="col-12 col-lg-6">
                                        {plCardDaily}
                                    </Col>
                                    <Col className="col-12 col-lg-6">
                                        {plCardTotal}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    zoomChange = (key, e) => {
        console.log('Changing zoom', key, e);
        this.setState({stage: 'zooming'}, () => {
            this.getData(e.target.dataset.level);
        });
    };

    zoomLinks () {
        const {zoom} = this.state;
        const levels = {
            '1d': '1 DAY',
            '1w': '1 WEEK',
            '1m': '1 MONTH',
            // Not relevant yet...
            // '3m': '3 MONTHS',
            // '6m': '6 MONTHS',
            // '8m': '8 MONTHS',
            // '1y': '1 YEAR',
            // 'all': 'ALL'
        };
        let links = [];
        for (const l in levels) {
            let className = 'dropdown-item';
            if (levels[l] === zoom) {
                className += ' active';
            }
            links.push(
                <Dropdown.Item className={className}
                               key={l}
                               data-level={l}
                               onSelect={this.zoomChange}>
                    {levels[l]}
                </Dropdown.Item>
            );
        }
        return (
            <div className="cp-investment-chart-zoom float-end">
                <DropdownButton id="zoom-button" title={levels[zoom]}>
                    {links}
                </DropdownButton>
            </div>
        );
    }
}

export default PortfolioInvestmentExpanded;
